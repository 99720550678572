import React from 'react';
import styled from 'styled-components';

const CardHeaderAvatar = styled.div`
  flex: 0 0 auto;
  margin-right: 16px;
`;

type AvatarCircleProps = {
  width?: number;
  height?: number;
  auto?: boolean;
};

export const AvatarCircle = styled.div<AvatarCircleProps>`
  width: ${({ width, auto }): string => `${auto ? '100%' : `${width || 30}px`}`};
  height: ${({ height, auto }): string => `${auto ? 'initial' : `${height || 30}px`}`};

  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
`;

interface CardAvatar {
  width?: number;
  height?: number;
  auto?: boolean;
  children?: JSX.Element | null;
}

const CardAvatar = ({ width, height, auto, children }: CardAvatar): JSX.Element => (
  <CardHeaderAvatar>
    <AvatarCircle width={width} height={height} auto={auto}>
      {children}
    </AvatarCircle>
  </CardHeaderAvatar>
);

export default CardAvatar;

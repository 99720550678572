import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import Hero from '../components/hero';
import { Container, Grid, Button } from '@material-ui/core';
import Promotion from '../components/promotion';
import LatestBlogPosts from '../components/latest-blog-posts';
import DefaultLink from '../components/DefaultLink';
import { HomeQueryQuery } from '../../types/graphql-types';
import HeroImage from '../components/image/hero';

export interface IndexPage {
  data: HomeQueryQuery;
}

const IndexPage = ({ data }: IndexPage): JSX.Element => {
  const { contentfulStartpage, allContentfulBlogPost } = data;
  const ogImage = contentfulStartpage?.ogImage?.gatsbyImageData?.images?.fallback?.src;
  const image = contentfulStartpage?.heroImageLarge?.gatsbyImageData;

  return (
    <>
      <SEO title="Home" image={ogImage} />
      {image && (
        <Hero>
          <HeroImage title="Hero" alt="hero" image={image} />
        </Hero>
      )}

      <Container>
        <Promotion
          heading={contentfulStartpage?.heading}
          premable={contentfulStartpage?.premable?.premable}
        >
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <DefaultLink href="/download" noTextDecoration>
                <Button variant="contained" color="primary">
                  Download
                </Button>
              </DefaultLink>
            </Grid>
            <Grid item>
              <DefaultLink href="/contact" noTextDecoration>
                <Button variant="outlined" color="primary">
                  Contact
                </Button>
              </DefaultLink>
            </Grid>
          </Grid>
        </Promotion>
        <LatestBlogPosts blogs={allContentfulBlogPost.edges} />
      </Container>
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulStartpage {
      heroImageLarge {
        gatsbyImageData(width: 1920, height: 1080, quality: 90)
      }
      ogImage: heroImageLarge {
        gatsbyImageData(width: 1200, height: 627, quality: 90)
      }
      heroHeading
      heroPremable {
        heroPremable
      }
      heading
      premable {
        premable
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }, limit: 3) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 880
              height: 440
              quality: 90
            )
          }
          description {
            description
          }
          author {
            title
            name
            slug
            image {
              gatsbyImageData(
                layout: FIXED
                placeholder: BLURRED
                width: 40
                height: 40
                quality: 90
              )
            }
          }
        }
      }
    }
  }
`;

import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Typography, CardHeader } from '@material-ui/core';
import MUCard, { CardProps as MUCardProps } from '@material-ui/core/Card';
import MUCardActions, {
  CardActionsProps as MUCardActionsProps,
} from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import DefaultLink from '../DefaultLink';
import CardAvatar from '../Avatar/CardAvatar';
import { Maybe } from '../../../types/graphql-types';

// const toLetters = (name?: string | null): string | undefined => name?.substr(0, 1);

type CardProps = MUCardProps & {
  horizontal?: boolean;
};

const stripCardProps = (props: CardProps): MUCardProps => {
  const next = { ...props };
  delete next.horizontal;
  return next;
};

const Card = styled((props: CardProps) => <MUCard {...stripCardProps(props)} />)<CardProps>`
  ${({ horizontal }): false | string | undefined =>
    horizontal &&
    `
  width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  `}
`;

type CardActionProps = MUCardActionsProps & {
  horizontal?: boolean;
};

const stripCardActionProps = (props: CardActionProps): MUCardActionsProps => {
  const next = { ...props };
  delete next.horizontal;
  return next;
};

const CardAction = styled((props: CardActionProps) => (
  <MUCardActions {...stripCardActionProps(props)} />
))<CardActionProps>`
  ${({ horizontal }): false | string | undefined =>
    horizontal &&
    `
  flex: 1 0 auto;
  `}
`;

interface GridCardProps {
  author: {
    image?: Maybe<IGatsbyImageData>;
    title?: string | null;
    name?: string | null;
    slug?: string | null;
  };
  image?: Maybe<IGatsbyImageData>;
  title?: string | null;
  publishDate: string;
  description?: string | null;
  slug?: string | null;
  horizontal?: boolean;
}

const GridCard = ({
  author,
  image,
  title,
  publishDate,
  description,
  slug,
  horizontal,
}: GridCardProps): JSX.Element => (
  <Card horizontal={horizontal}>
    <CardHeader
      avatar={
        <CardAvatar width={40} height={40}>
          {author.image && <GatsbyImage image={author.image} alt={author.title || 'Profile'} />}
        </CardAvatar>
      }
      title={<DefaultLink href={author?.slug}>{author?.name}</DefaultLink>}
      subheader={author?.title}
    />
    {image && <GatsbyImage image={image} alt={author.title || 'Image'} />}
    <CardContent>
      <Typography gutterBottom variant="h5" component="h2">
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        {publishDate}
      </Typography>
      <br />
      <Typography variant="body2" color="textSecondary" component="p">
        {description}
      </Typography>
    </CardContent>
    <CardAction horizontal={horizontal}>
      <DefaultLink href={`/blog/${slug}`} style={{ textDecoration: 'none' }}>
        <Button size="small" color="primary">
          Read more
        </Button>
      </DefaultLink>
    </CardAction>
  </Card>
);

export default GridCard;

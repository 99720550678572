import React from 'react';

import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import GridCard from '../Blog/GridCard';
import { HomeQueryQuery } from '../../../types/graphql-types';

const StyledGrid = styled(Grid)`
  display: flex;
`;

interface LatestBlogPostProps {
  blogs: HomeQueryQuery['allContentfulBlogPost']['edges'];
}

const LatestBlogPost = ({ blogs }: LatestBlogPostProps): JSX.Element => {
  return (
    <Box mt={5}>
      <Grid container spacing={4} alignItems="stretch">
        {blogs.map(({ node }) => (
          <StyledGrid item key={node.slug} xs={12} sm={12} md={4}>
            <GridCard
              horizontal
              author={{
                image: node?.author?.image?.gatsbyImageData || undefined,
                name: node?.author?.name,
                title: node?.author?.title,
                slug: `/team/${node?.author?.slug}`,
              }}
              image={node?.heroImage?.gatsbyImageData || undefined}
              title={node.title}
              publishDate={node?.publishDate}
              description={node?.description?.description}
              slug={node.slug}
            />
          </StyledGrid>
        ))}
      </Grid>
    </Box>
  );
};

export default LatestBlogPost;
